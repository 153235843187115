@import "./variables";

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
p {
  color: black;
  text-decoration: none;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ----- FLEXBOX GLOBAL STYLES -----*/
.fx-rw {
  display: flex;
  flex-direction: row;
}

.fx_ac {
  display: flex;
  align-items: center;
}

.fx_jc {
  display: flex;
  justify-content: center;
}

.fx-sb {
  display: flex;
  justify-content: space-between;
}

.fx-col {
  display: flex;
  flex-direction: column;
}

.fx-se {
  display: flex;
  justify-content: space-evenly;
}

.react-select__control {
  width: 100%;
  outline: none;
  border: 1px solid $color_green3 !important;
  padding: 0.4rem;
  border-radius: $border_radius !important;
  box-sizing: border-box;

  &--is-focused {
    box-shadow: 0 0 0 1px $color_green2 !important;
  }
}
